/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

ngb-popover-window {
  position: absolute;
  width: auto !important;
  display: block !important;
  border: 1px solid #fafafa !important;
  max-width: none !important;
}
.popover-header {
  padding: 8px 14px;
  margin: 0;
  font-size: 14px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: 5px 5px 0 0;
}
.popover-body {
  padding: 8px 14px;
  font-size: 14px;
}
textarea {
  resize: vertical;
}
.row.margin-top {
  margin-top:15px;
}
.row.margin-bottom {
  margin-bottom:15px;
}
kendo-numerictextbox.k-numerictextbox {
  width: 100%;
}
kendo-numerictextbox .k-numeric-wrap {
  border: 0;
}
kendo-numerictextbox .k-numeric-wrap input.k-input {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

kendo-numerictextbox .k-numeric-wrap input.k-input[disabled], kendo-numerictextbox .k-numeric-wrap input.k-input[readonly] {
  background-color: #eee;
  opacity: 1;
}
.k-state-disabled {
  opacity: 1;
}
.k-textbox::selection, .k-input::selection, .k-textarea::selection {
  background: #3297FD;
  color: #fff;
}

::selection {
  background: #3297FD;
  color: #fff;
}

